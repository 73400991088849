var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bck-white", staticStyle: { height: "100%" } },
    [
      _c("Navbar"),
      _c(
        "transition",
        { attrs: { name: "modal-fade", mode: "out-in" } },
        [
          _vm.projectDetails == null ? _c("Loader", { key: "1" }) : _vm._e(),
          _vm.projectDetails
            ? _c("section", { key: "2", staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 side_div" }, [
                    _c("div", { staticClass: "left_div" }, [
                      _c("div", { staticClass: "div_go_back" }, [
                        _c(
                          "span",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.goBack },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/onboarding/back-icon.svg" },
                            }),
                            _c("span", { staticClass: "go_back_span" }, [
                              _vm._v("Go Back"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "div_explain" }, [
                        _c("div", { staticClass: "page_header" }, [
                          _vm._v(
                            "\n                Meet and Discuss your idea with "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.projectTeam.team_name) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "sprint_text" }, [
                          _vm._v("\n                This is a "),
                          _c("b", { staticStyle: { color: "#1b1e22" } }, [
                            _vm._v("30-minute"),
                          ]),
                          _vm._v(
                            " meeting with\n                the product team to get to know them, have a brief discussion\n                and decide if you would like to continue with them.\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "sprint_text",
                            staticStyle: { "margin-top": "32px" },
                          },
                          [
                            _vm._v("\n                DURATION: "),
                            _c("span", { staticStyle: { color: "#15171a" } }, [
                              _vm._v("30 minutes"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "grupa-protection" }, [
                          _c("div", { staticClass: "icon-div" }, [
                            _c("img", {
                              attrs: { src: "/img/onboarding/lock.svg" },
                            }),
                          ]),
                          _c("div", { staticClass: "protection-text" }, [
                            _vm._v(
                              "\n                  Your product idea is protected by Grupa’s\n                  "
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.ndaModal = true
                                  },
                                },
                              },
                              [_vm._v("Mutual NDA")]
                            ),
                            _vm._v(
                              ", and this\n                  meeting is covered by Grupa’s\n                  "
                            ),
                            _c("span", [_vm._v("Non-Solicitation Policy")]),
                            _vm._v(".\n                "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "right_div" }, [
                      _vm.user && !_vm.user.discount_id
                        ? _c("div", { staticClass: "amount_div" }, [
                            _c("div", { staticClass: "cost_span" }, [
                              _vm._v("Cost"),
                            ]),
                            _c("div", { staticClass: "amount-discount" }, [
                              _vm._v("$500"),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.user && _vm.user.discount_id
                        ? _c("div", { staticClass: "amount_div" }, [
                            _c("div", { staticClass: "cost_span" }, [
                              _vm._v("Cost"),
                            ]),
                            _c("div", { staticClass: "amount_span" }, [
                              _c("div", { staticClass: "amount-discount" }, [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      500 -
                                        500 *
                                          (_vm.user.discount.discount_value /
                                            100)
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _c("div", { staticClass: "discount-wrapper" }, [
                                _vm._v("$500"),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "sprint_tasks" },
                        [
                          _c("div", { staticClass: "top_text" }, [
                            _vm._v("WHAT YOU GET"),
                          ]),
                          _vm._l(_vm.meeting_tasks, function (item, k) {
                            return _c(
                              "div",
                              { key: k, staticClass: "item_list" },
                              [
                                _c("img", {
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    src: "/img/onboarding/checkout.svg",
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "div_btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "grupa-blue-btn",
                            staticStyle: { height: "45px" },
                            on: { click: _vm.openPaymentMethods },
                          },
                          [
                            _c("img", {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { src: "/img/onboarding/grey_lock.svg" },
                            }),
                            _vm._v(
                              "\n                Pay & Setup Meeting\n              "
                            ),
                          ]
                        ),
                        _vm.user && !_vm.user.discount_id
                          ? _c("div", { staticClass: "voucher-text" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userVoucher,
                                    expression: "userVoucher",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "voucher",
                                  placeholder: "Enter discount code",
                                  type: "text",
                                },
                                domProps: { value: _vm.userVoucher },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.userVoucher = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: { top: "30%" },
                                  on: { click: _vm.handleVoucher },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.user && _vm.user.discount_id
                          ? _c("div", { staticClass: "voucher-text" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.discount.code,
                                    expression: "user.discount.code",
                                  },
                                ],
                                staticClass: "form-control",
                                staticStyle: {
                                  background: "#f7f7f8",
                                  color: "#979da5",
                                },
                                attrs: {
                                  disabled: "",
                                  name: "voucher",
                                  placeholder: "Enter discount code",
                                  type: "text",
                                },
                                domProps: { value: _vm.user.discount.code },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user.discount,
                                      "code",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: { opacity: "0.4" },
                                  on: { click: _vm.handleVoucher },
                                },
                                [_vm._v("Apply")]
                              ),
                              _c(
                                "div",
                                { staticClass: "discount-information" },
                                [
                                  _vm._v(
                                    "\n                  A discount of " +
                                      _vm._s(_vm.user.discount.discount_value) +
                                      "% has been\n                  applied to your Grupa account for all payments, forever.\n                "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "revert-discount" }, [
                                _vm._v("Revert Discount"),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "schedule-meeting" }, [
                        _vm._v("\n              Unsure?\n              "),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.showCalendly = true
                              },
                            },
                          },
                          [_vm._v("Schedule 10 minutes call with Team Lead")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "dev-stage" },
                    [
                      _c(
                        "DevelopmentStage",
                        _vm._b(
                          {},
                          "DevelopmentStage",
                          { projectDetails: _vm.projectDetails },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "faq-section" },
                    [_c("FaqSection")],
                    1
                  ),
                  _c("div", { staticClass: "footer-div" }, [
                    _vm._v(
                      "\n          Have any unanswered questions?\n          "
                    ),
                    _c("span", { staticStyle: { color: "#0781f2" } }, [
                      _vm._v(" Have a chat with us"),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.openStripe
        ? _c(
            "StripeModal",
            _vm._b(
              {
                attrs: { amount: _vm.teamEvaluationFee },
                on: { close: _vm.closeModal },
              },
              "StripeModal",
              {
                user: _vm.user,
                modalType: _vm.modalType,
                paymentInfo: _vm.paymentInfo,
                userCreditCard: _vm.userCreditCard,
              },
              false
            )
          )
        : _vm._e(),
      _vm.ndaModal
        ? _c("NonDisclosureAgreement", { on: { close: _vm.closeNda } })
        : _vm._e(),
      _vm.showCalendly
        ? _c(
            "CalendlyModal",
            _vm._b(
              {
                attrs: { client: true },
                on: { closeModal: _vm.closeCalendly },
              },
              "CalendlyModal",
              { loadCalendly: _vm.loadCalendly },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }