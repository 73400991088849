var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faq-conainer" }, [
    _c("div", { staticClass: "next-step" }, [_vm._v("WHAT’S NEXT?")]),
    _c("div", { staticClass: "section-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "section-box" }, [
        _vm._m(1),
        _c("div", { staticClass: "section-title" }, [
          _vm._v("2. Design Sprint and Roadmapping"),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "cost" }, [
          _vm._v(
            "\n        Cost: $\n        " +
              _vm._s(
                _vm.projectDetails.design_thinking_fee.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )
              )
          ),
          _c("span", { staticStyle: { color: "#979da5" } }, [
            _vm._v("(you pay 50% upfront)"),
          ]),
        ]),
      ]),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-box" }, [
      _c("div", { staticClass: "head-title" }, [
        _c("img", { attrs: { src: "/img/onboarding/green-bullet.svg" } }),
        _vm._v(" - You’re here now\n      "),
      ]),
      _c("div", { staticClass: "section-title" }, [
        _vm._v("1. Meet and Evaluate Product Team"),
      ]),
      _c("div", { staticClass: "section-content" }, [
        _vm._v(
          "\n        Meet with the product team you have reserved before making a final\n        decision on whether to work with them or choose a different team.\n      "
        ),
      ]),
      _c("div", { staticClass: "cost" }, [_vm._v("Cost: $500")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-title" }, [
      _c("img", { attrs: { src: "/img/onboarding/unchecked.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-content" }, [
      _vm._v("\n        Go through the\n        "),
      _c("span", { staticStyle: { color: "#0781f2" } }, [
        _vm._v("design sprint"),
      ]),
      _vm._v(
        " sessions that help\n        to get the team invested in your idea and ends with a clear product\n        development roadmap.\n      "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-box" }, [
      _c("div", { staticClass: "head-title" }, [
        _c("img", { attrs: { src: "/img/onboarding/unchecked.svg" } }),
      ]),
      _c("div", { staticClass: "section-title" }, [
        _vm._v("3. Kick off Product Development"),
      ]),
      _c("div", { staticClass: "section-content" }, [
        _vm._v(
          "\n        Kick off product development with the first iteration as outlined in\n        the roadmap.\n      "
        ),
      ]),
      _c("div", { staticClass: "cost" }, [
        _vm._v(
          "\n        Cost: Depends on service plan and number of iterations\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }