var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "terms_modal_backdrop" }, [
    _c(
      "div",
      { staticClass: "terms_modal", staticStyle: { "text-align": "left" } },
      [
        _c("div", { staticClass: "modal-title" }, [
          _c("span"),
          _vm._v(" NON-DISCLOSURE AGREEMENT\n            "),
          _c("img", {
            staticStyle: {
              float: "right",
              cursor: "pointer",
              "margin-top": "10px",
            },
            attrs: { src: "/img/dashboard/dev/close.svg" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "WordSection1",
            staticStyle: { "tab-interval": ".5in" },
          },
          [
            _vm._m(0),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: { "margin-top": "6.0pt", "text-align": "justify" },
              },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n            By registering and subscribing to the Grupa platform, you agree to be bound \n            by the terms of this Agreement that apply for each country\n             for which you register or elect to use a service (“Elected Country”). \n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: { "margin-top": "6.0pt", "text-align": "justify" },
              },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _c("span", { staticStyle: { "mso-tab-count": "1" } }),
                    _vm._v(
                      "\n            In this Agreement, “we”, “us”, and “Grupa” means the applicable Platohub\n             Contracting Party and any of its applicable Affiliates, and “you” means\n              the applicant (if registering for or using a Service as an individual),\n               or the business employing or representing the applicant (if registering \n               for or using a service as a business) and any of its affiliates. You and\n                Grupa mean the “Parties”. Capitalized terms have the meanings given to them \n                in this Agreement. If there is a conflict among terms in this Agreement, the\n                 Service Level Agreement to be signed will prevail over this Agreement.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: { "margin-top": "6.0pt", "text-align": "justify" },
              },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _c("span", { staticStyle: { "mso-tab-count": "1" } }),
                    _vm._v("WHEREAS:"),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(1),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n        You are engaging Grupa in relation to possible engagement to build\n         innovative solutions (Services)and in the course of this engagement,\n         both Parties will come into contact with, obtain or receive information\n         that is considered Confidential Information (as hereinafter defined). "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(2),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n        The Parties acknowledge that any confidential information exchanged\n         between them should be kept confidential and have therefore agreed to enter into this Agreement\n        \n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(3),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n        To begin the enrollment process, you must complete the registration\n         process for one or more of the Services. Use of the Services is limited\n         to parties that can lawfully enter into and form contracts under applicable\n         Law (for example, the Elected Country may not allow minors to use the Services).\n         As part of the application, you must provide us with your (and/or your business)\n         legal name, address, phone number and email address.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: { "margin-top": "6.0pt", "text-align": "justify" },
              },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _c("span", { staticStyle: { "mso-tab-count": "1" } }),
                    _vm._v("NOW IT IS HEREBY AGREED AS FOLLOWS:"),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(4),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n        DEFINITIONS\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(5),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n            “Agreement” means this Confidentiality and Non-Disclosure Agreement between the Parties;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(6),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n             “Confidential Information” means all information or data disclosed (whether in writing, orally or by any other means) to one party by the other party or by a third party on behalf of the other party and shall include but not be limited to; \n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.0pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.0pt",
                  "mso-list": "l0 level2 lfo1",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(7),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            Information relating to the organizational structure, marketing strategy \n            and product roadmap of the Parties and any other information relating to\n             the business operations, processes, plans, intentions, product information,\n              know-how, design rights, trade secrets, software, market opportunities,\n               customers and affairs of the Parties;\n               "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(8),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            technical information to any products or services supplied by the Parties;\n             "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(9),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            information relating to the officers, directors or employees of the Parties \n            including their terms and conditions of employment or engagement;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(10),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            information which is disclosed orally and identified as confidential at the time of disclosure;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(11),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            The existence and content of this Agreement and matters incidental thereto;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(12),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            any information ascertainable by the inspection of accounts, and other records;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(13),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            financial, legal and any other information provided in due diligence checklists to be provided after execution here\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(14),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            any information relating to that Party’s business, operations, plans, intentions, market opportunities, customers and business affairs; and\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(15),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            any Intellectual Property.\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _c("span", { staticStyle: { "font-size": "14px" } }),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n           But shall exclude any part of such disclosed information or data and information supplied by the Parties which:\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(16),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            is or becomes common or public knowledge in any way without breach of this Agreement by the receiving party; or\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(17),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            the receiving party can show; (i) was in its possession or known to it by being\n             in its use or being recorded in its files or computers or other recording media\n              prior to receipt from the disclosing party and was not previously acquired by the \n              receiving party from the disclosing party under an obligation of confidence; or (ii)\n               to have been developed by or for the receiving party at any time independently of the\n                information disclosed to it by the disclosing party; or\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(18),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            which is hereafter disclosed or made available to the receiving party \n            from a source other than the disclosing party without breach by the \n            receiving party or such source of any obligations of confidentiality\n             or non-use towards the disclosing party; or\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(19),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            which is hereafter disclosed by the disclosing party to a third party \n            without restriction on disclosure or use, including, without limitation,\n             by way of the publication of a patent specification; or\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "3.0pt",
                  "margin-right": "0in",
                  "margin-left": "63.35pt",
                  "margin-bottom": ".0001pt",
                  "text-indent": "-27.35pt",
                  "tab-stops": ".5in 63.0pt",
                },
              },
              [
                _vm._m(20),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            which is disclosed by the receiving Party with the prior written approval of the disclosing Party.\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(21),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            “Effective Date” means the date upon which this agreement shall be deemed to be the date of accepting to use our Services;\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(22),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      '\n        "Intellectual Property" shall mean any company name, registered name, trade mark,\n         logo, copyright, trade name, or other intellectual property right whatsoever of\n          the disclosing Party, whether registered or not;\n          '
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(23),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [
                    _vm._v(
                      "\n        HANDLING OF CONFIDENTIAL INFORMATION\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _c("span", { staticStyle: { "font-size": "14px" } }),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n          In consideration of the exchange and disclosure of Confidential Information,\n           each Party (“the Recipient”) undertakes in relation to the other Party’s \n           (“the Disclosing Party”) Confidential Information:\n            "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(24),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        to maintain the same in confidence and to use it only for purposes \n        of the negotiations contemplated herein;\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(25),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        not to disclose the same except to its directors, officers, employees, agents, consultants, \n        legal, tax and investment advisors (collectively, the “Representatives”) and except in confidence\n         to such of its Representatives who need to know the same for purposes of the information disclosures\n         contemplated herein and that such Representatives are obliged not to disclose the same;\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(26),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        to be responsible for the performance of sub clauses 2.1, and 2.2 above on the part of\n         its Representatives to whom the same is disclosed and;\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(27),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        to apply thereto no lesser security measures and degree of care than those which \n        the Receiving Party applies to its own Confidential Information and which the Receiving \n        Party warrants as providing adequate protection of such information from unauthorized \n        disclosure, copying or use.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(28),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [
                    _vm._v("\n        LENGTH OF THIS AGREEMENT\n        "),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _c("span", { staticStyle: { "font-size": "10.0pt" } }),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This Agreement shall be in force from the date herein stated and shall expire\n             within 12 months from the Effective Date of this Agreement. \n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(29),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [
                    _vm._v("\n        OWNERSHIP AND NO WARRANTY\n        "),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(30),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Each Party reserves all rights in its Confidential Information \n        and no rights or obligations other than those expressly  cited \n        herein are granted or to be implied from this Agreement.."
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(31),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        The Receiving Party shall not acquire hereunder any right whatsoever\n         to any Confidential Information, including without limitation, any right or\n          license of any patent, trademark, copyright, trade secret, moral right or any\n           other right now or later recognized by any law or regulation of any jurisdiction\n            as a result of or in connection with any disclosure hereunder. Accordingly, nothing\n             in this Agreement is intended or shall be construed as a transfer, grant, license,\n              release or waiver of any Intellectual Property Rights in any Confidential Information.\n              "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(32),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        No disclosure of any Confidential Information by the Parties shall constitute\n         any representation or warranty by that Party regarding the accuracy of the same\n          or the non-infringement of any patent, trademark, copyright or any other intellectual\n           property or proprietary right.\n           "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(33),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Nothing in this Agreement shall be deemed to obligate either Party\n         to disclose any Confidential Information to the other, or to accept\n          any Confidential Information of the other."
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(34),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [
                    _vm._v(
                      "\n        RETURN/DESTRUCTION OF CONFIDENTIAL INFORMATION\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(35),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Each Party shall immediately after the termination of this Agreement or at the request\n         of the other Party either destroy or return all Confidential Information and any copies\n          thereof in its possession and within thirty (30) days of receiving such a request\n           certify in writing its compliance."
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(36),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This clause shall not apply to the extent that the Receiving Party is obliged to retain\n         any Confidential Information in terms of any applicable law, rule or regulation or by any\n          competent judicial, governmental, supervisory or regulatory body or in accordance with\n           reasonable internal policy.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(37),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n        FURTHER PROTECTION\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(38),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        In the event that either Party becomes compelled by law or by order of any court or \n        tribunal of competent jurisdiction to disclose any Confidential Information, \n        the said Party shall give the other Party prompt notice but in any event not \n        later than five (5) days’ notice prior to such disclosure (where possible) so \n        that the other Party may seek a protective order or other appropriate remedy \n        and /or waive compliance with the provisions of this Agreement\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(39),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n        APPLICABLE LAW\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(40),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This Agreement shall be exclusively governed and construed in accordance with the Laws\n         of Delaware and the same law shall exclusively govern the merits of any dispute\n          arising out of or in connection with this Agreement.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(41),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n        DISPUTE RESOLUTION\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(42),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        The Parties shall use their best efforts to settle amicably any dispute arising\n         from or in connection with this Agreement or the interpretation thereof.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(43),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        If the dispute has not been settled pursuant to clause 8.1 above within 1 (one) month \n        from when the mediation was instituted, any party may elect to seek redress at\n         the relevant Chancery courts of law in Delaware.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(44),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Pending final settlement or determination of a dispute, the Parties shall continue to perform their subsisting obligations hereunder.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(45),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Nothing in this Agreement shall prevent or delay a Party seeking urgent \n        injunctive or interlocutory relief in a court having jurisdiction.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(46),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n            NOTICE\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(47),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        The Parties choose their respective addresses as provided for the purposes of giving\n         any notice, the service of any process and for any purpose arising from this Agreement.  \n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(48),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Each of the Parties shall be entitled, by written notice to the other, to vary their\n         address for notice from time to time to any other address.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(49),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      '\n        Any notice given by one Party to the other ("the Addressee") which: \n        '
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(50),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            is delivered by hand during the normal business hours of the Addressee at\n             the Addressee's address for notice for the time being shall be deemed, \n             (unless the contrary is proved by the Addressee), to have been received\n              by the Addressee at the time of delivery;\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(51),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        if transmitted by facsimile to the facsimile number forming a part of the Addressee's\n         address for notice, or sent by email to the e-mail address of such person previously\n          provided to the other Party, be deemed to have been received by the Addressee\n           (unless the contrary is proved by the Addressee) one\n            Business Day after the date of dispatch.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(52),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Notwithstanding anything to the contrary contained in this Agreement, a written notice \n        or communication actually received by one of the Parties from another\n        will be adequate written notice or communication to such Party.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(53),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n            NO PUBLICATION\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(54),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        Neither Party shall disclose, publicize or advertise in any manner the discussions or\n         negotiations contemplated by the Agreement without the prior written consent of\n          the other Party, except as may be required by law.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoNormal",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-bottom": ".0001pt",
                  "margin-left": "0in",
                  "text-align": "justify",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": ".5in",
                },
              },
              [
                _vm._m(55),
                _c(
                  "span",
                  { staticStyle: { "font-size": "14px", color: "blue" } },
                  [_vm._v("\n            GENERAL\n        "), _c("o:p")],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(56),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This Agreement and any amendment thereto may be executed in counterparts, each of which\n         when executed and delivered shall be deemed an original and all of which taken together\n          shall constitute one and the same instrument.  This Agreement may be delivered by hand\n           or e-mail (as a signed PDF).\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(57),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n            This Agreement shall not be deemed to create any partnership\n             or agency between the Parties, the rights and obligations arising\n              from this Agreement being restricted to this Agreement.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(58),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This Agreement constitutes the entire Agreement and understanding between the \n        parties in respect of Confidential Information and supersedes all previous \n        agreements, understandings and undertakings in such respect.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(59),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        This Agreement is personal to the parties and shall not be assigned or\n        otherwise transferred in whole or in part by either party.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(60),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        The invalidity or unenforceability of any provision of this Agreement shall \n        not affect the validity or enforceability of any other provision of this Agreement\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(61),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        We may amend any of the terms and conditions contained in this Agreement at any time\n         and at our sole discretion. Any changes will be effective upon the posting or updating\n          such changes on the Grupa platform and you are responsible for reviewing and informing\n           yourself of all applicable changes or notices.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "MsoBodyTextIndent",
                staticStyle: {
                  "margin-top": "6.0pt",
                  "margin-right": "0in",
                  "margin-left": "0in",
                  "margin-bottom": ".0001pt",
                  "text-indent": "0in",
                  "mso-list": "l0 level1 lfo1",
                  "tab-stops": "list .5in",
                },
              },
              [
                _vm._m(62),
                _c(
                  "span",
                  { staticStyle: { "font-size": "10.0pt" } },
                  [
                    _vm._v(
                      "\n        The signatories on behalf of the Parties warrant that they are duly authorized hereto.\n        "
                    ),
                    _c("o:p"),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoNormal" }, [
      _c("span", [
        _c("b", [
          _vm._v(
            " THIS CONFIDENTIALITY AND NON - DISCLOSURE AGREEMENT (“this Agreement”)\n             is made between PLATOHUB INC.,"
          ),
        ]),
        _vm._v(
          " a corporation registered under the laws \n             of the State of Delaware and with registered office address at 1013 Centre Road,\n              Suite 403-A, Wilmington, New Castle County, Delaware, 19805 (trading under the brand \n              name “Grupa”) and you (on behalf of yourself or the business you represent). \n        \n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("A."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("B."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("C."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("1."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(a)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(b)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("      \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(c)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(d)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("      \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(e)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(f)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(g)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(h)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(i)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(a)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(b)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(c)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(d)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(e)"),
        _c("span", { staticStyle: { "margin-right": "0px" } }, [
          _vm._v("       \n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("2."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("2.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("2.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("2.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("2.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("3."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("4."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("4.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("4.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("4.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("4.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("5."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("5.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("5.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("6."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("7."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("8."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("8.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("8.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("8.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("8.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("9."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("9.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("9.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("9.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(a)"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("(b)"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("9.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("10."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore", color: "blue" } }, [
        _vm._v("11."),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.1"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.2"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.3"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.4"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.5"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.6"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10.0pt" } }, [
      _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
        _vm._v("1.7"),
        _c("span", { staticStyle: { "margin-right": "8px" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }