<template>
  <div class="bck-white" style="height: 100%">
    <Navbar />
    <transition name="modal-fade" mode="out-in">
      <Loader key="1" v-if="projectDetails == null" />
      <section v-if="projectDetails" key="2" class="container-fluid">
        <div class="row">
          <div class="col-md-6 side_div">
            <div class="left_div">
              <div class="div_go_back">
                <span @click="goBack" style="cursor: pointer">
                  <img src="/img/onboarding/back-icon.svg" />
                  <span class="go_back_span">Go Back</span>
                </span>
              </div>
              <div class="div_explain">
                <div class="page_header">
                  Meet and Discuss your idea with <br />
                  {{ projectTeam.team_name }}
                </div>
                <div class="sprint_text">
                  This is a <b style="color: #1b1e22">30-minute</b> meeting with
                  the product team to get to know them, have a brief discussion
                  and decide if you would like to continue with them.
                </div>
                <div class="sprint_text" style="margin-top: 32px">
                  DURATION: <span style="color: #15171a">30 minutes</span>
                </div>
                <div class="grupa-protection">
                  <div class="icon-div">
                    <img src="/img/onboarding/lock.svg" />
                  </div>
                  <div class="protection-text">
                    Your product idea is protected by Grupa’s
                    <span @click="ndaModal = true">Mutual NDA</span>, and this
                    meeting is covered by Grupa’s
                    <span>Non-Solicitation Policy</span>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="right_div">
              <div class="amount_div" v-if="user && !user.discount_id">
                <div class="cost_span">Cost</div>
                <div class="amount-discount">$500</div>
              </div>
              <div class="amount_div" v-if="user && user.discount_id">
                <div class="cost_span">Cost</div>
                <div class="amount_span">
                  <div class="amount-discount">
                    $ {{ 500 - 500 * (user.discount.discount_value / 100) }}
                  </div>
                  <div class="discount-wrapper">$500</div>
                </div>
              </div>
              <div class="sprint_tasks">
                <div class="top_text">WHAT YOU GET</div>
                <div
                  class="item_list"
                  v-for="(item, k) in meeting_tasks"
                  :key="k"
                >
                  <img
                    src="/img/onboarding/checkout.svg"
                    style="margin-right: 8px"
                  />
                  {{ item }}
                </div>
              </div>
              <div class="div_btn">
                <button
                  class="grupa-blue-btn"
                  style="height: 45px"
                  @click="openPaymentMethods"
                >
                  <img
                    src="/img/onboarding/grey_lock.svg"
                    style="margin-right: 10px"
                  />
                  Pay & Setup Meeting
                </button>
                <div class="voucher-text" v-if="user && !user.discount_id">
                  <input
                    class="form-control"
                    name="voucher"
                    v-model="userVoucher"
                    placeholder="Enter discount code"
                    type="text"
                  />
                  <span style="top: 30%" @click="handleVoucher">Apply</span>
                </div>
                <div class="voucher-text" v-if="user && user.discount_id">
                  <input
                    disabled
                    style="background: #f7f7f8; color: #979da5"
                    class="form-control"
                    name="voucher"
                    v-model="user.discount.code"
                    placeholder="Enter discount code"
                    type="text"
                  />
                  <span style="opacity: 0.4" @click="handleVoucher">Apply</span>
                  <div class="discount-information">
                    A discount of {{ user.discount.discount_value }}% has been
                    applied to your Grupa account for all payments, forever.
                  </div>
                  <div class="revert-discount">Revert Discount</div>
                </div>
              </div>
              <div class="schedule-meeting">
                Unsure?
                <span @click="showCalendly = true"
                  >Schedule 10 minutes call with Team Lead</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="dev-stage">
            <DevelopmentStage v-bind="{ projectDetails }" />
          </div>
          <div class="faq-section">
            <FaqSection />
          </div>
          <div class="footer-div">
            Have any unanswered questions?
            <span style="color: #0781f2"> Have a chat with us</span>
          </div>
        </div>
      </section>
    </transition>
    <StripeModal
      v-if="openStripe"
      v-on:close="closeModal"
      v-bind:amount="teamEvaluationFee"
      v-bind="{ user, modalType, paymentInfo, userCreditCard }"
    />
    <NonDisclosureAgreement v-if="ndaModal" v-on:close="closeNda" />

    <CalendlyModal
      v-if="showCalendly"
      v-bind:client="true"
      v-on:closeModal="closeCalendly"
      v-bind="{ loadCalendly }"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/navbar";
import { mapState, mapActions } from "vuex";
import { completeProjectDetails, clientPaymentCards } from "@/api";
import { clientApplyVoucher } from "@/api/client";
import Loader from "@/components/general/loader";
import NonDisclosureAgreement from "@/components/general/nda";
import DevelopmentStage from "./developmentStage";
import FaqSection from "./faqSection";
import StripeModal from "@/components/stripeModal";
// import GrupaNDA from "@/components/general/nda";
import CalendlyModal from "@/components/calendlyModal";

export default {
  components: {
    Navbar,
    Loader,
    DevelopmentStage,
    FaqSection,
    StripeModal,
    NonDisclosureAgreement,
    CalendlyModal,
  },
  data: () => ({
    openStripe: false,
    modalType: "design_sprint",
    paymentInfo: {
      purpose: "team preview",
      payment_plan: "none",
      iteration_id: null,
    },
    meeting_tasks: [
      "Meet the Team",
      "Check cultural fit",
      "Change team if you’re not satisfied",
    ],
    publishableKey: process.env.VUE_APP_STRIPE_PUB_KEY,
    projectDetails: null,
    ip: "",
    userCreditCard: [],
    openCardList: false,
    paySpinner: false,
    defaultCardId: null,
    projectTeam: {},
    ndaModal: false,
    ndaModal: false,
    userVoucher: "",
    teamEvaluationFee: 500,
    showCalendly: false,
    loadCalendly: true,
  }),
  created() {
    const id = this.$route.params.id;
    this.getClientProject(id);
    this.clientCreditCard();
    this.teamName = this.projectTeam.user_name;
  },
  watch: {
    "user.discount_id": {
      immediate: true,
      handler: function (val) {
        this.teamEvaluationFee =
          500 - 500 * (this.user.discount.discount_value / 100);
      },
    },
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    ...mapActions("userManagement", ["updateCurrentUser"]),
    goBack() {
      let teamName = this.projectTeam.user_name;
      this.$router.push({
        name: "view_team",
        params: { user_name: teamName, id: this.$route.params.id },
      });
    },
    closeNda() {
      this.ndaModal = false;
    },
    getClientProject(id) {
      completeProjectDetails(id)
        .then((res) => {
          //   if (res.data.project.workspace_progress_level > 1) {
          //     this.$router.push({ name: "workspace", params: { id } });
          //   } else {
          this.projectDetails = res.data.project;
          this.projectTeam = res.data.team_members[0];
          this.updateProject(res.data.project);
          this.paymentInfo.iteration_id = res.data.documents.iterations[0].id;
          this.$developConsole(res.data, "project details");
          //   }
        })
        .catch((err) => {});
    },
    closeModal() {
      this.openStripe = false;
    },
    closeCalendly() {
      this.showCalendly = false;
    },
    priceRoundUp(data) {
      let price = data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: price.substring(0, price.length - 2),
        decimal: price.substring(price.length - 2, price.length),
      };
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.userCreditCard = response.data.cards;
          this.$developConsole(response.data.cards, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    openPaymentMethods() {
      this.openStripe = true;
    },
    handleVoucher() {
      let code = this.userVoucher;
      let payload = { discount_code: code };
      let project_id = this.$route.params.id;
      clientApplyVoucher(payload, project_id)
        .then((response) => {
          this.updateCurrentUser(response.data.data);
          this.$developConsole(response.data.data, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
  },

  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("devDashboard", ["selectedProject"]),
    user() {
      return this.currentUser;
    },
    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { page_padding: " 0px 0px", pagePadding: "10px 10px" };
        case "sm":
          return { page_padding: " 0px 0px", pagePadding: "10px 10px" };
        case "md":
          return { page_padding: "0px 50px", pagePadding: "10px 30px" };
        case "lg":
          return { page_padding: "0px 125px", pagePadding: "30px 50px" };
        case "xl":
          return { page_padding: "0px 125px", pagePadding: "30px 50px" };
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.side_div {
  justify-content: center;
  display: flex;
  padding-bottom: 120px;
}
.div_go_back {
  text-align: initial;
  margin-top: 38px;
}
.go_back_span {
  color: #0055a5;
  font-size: 14px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
}
.page_header {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  margin-top: 48px;
  width: 390px;
}
.sprint_text {
  margin-top: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  width: 410px;
  text-align: left;
}
.amount_div {
  margin-top: 106px;
}
.cost_span {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  text-transform: uppercase;
  color: #979da5;
}
.amount-discount,
.amount_span {
  margin-top: 8px;
  font-weight: 300;
  font-size: 46px;
  line-height: 100%;
  color: #15171a;
  display: flex;
}
.amount_span {
  font-size: 14px;
  line-height: 145%;
  color: #0eaa5f;
}
.discount-wrapper {
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: line-through;
  color: #d45b54;
  margin-left: 8px;
  margin-top: 28px;
}
.sprint_tasks {
  margin-top: 24px;
}
.item_list {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #15171a;
  margin-bottom: 12px;
}
.top_text {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #979da5;
  margin-bottom: 8px;
}
.right_div {
  width: 476px;
  text-align-last: left;
}
.left_div {
  /* display:flex; */
}
.div_explain {
  width: 393px;
  text-align: left;
}
.div_btn {
  margin-top: 48px;
  display: flex;
}

.payment {
  margin-bottom: 64px;
  margin-top: 48px;
  width: 284px;
  text-align: left;
}
.head_txt {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  text-transform: uppercase;
  color: #979da5;
}
.txt_below {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #15171a;
  margin-top: 16px;
}
.grupa-protection {
  background: rgba(84, 207, 148, 0.05);
  border-radius: 4px;
  width: 393px;
  padding: 16px;
  display: flex;
  margin-top: 52px;
}
.protection-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  align-items: center;
  color: #53585f;
  margin-left: 8px;
}
.protection-text span {
  color: #0055a5;
}
.item_list:nth-last-of-type(1) {
  margin-bottom: 0px;
}
.dev-stage {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  padding: 48px 0px;
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
.faq-section {
  border-bottom: 1px solid #e4e5e7;
  padding: 48px 0px;
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
.protection-text span {
  cursor: pointer;
}
.footer-div {
  margin-top: 24px;
  margin-bottom: 73px;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.voucher-text {
  position: relative;
  width: 278px;
  text-align: left;
  margin-left: 24px;
  width: 249px;
}
.voucher-text .form-control {
  height: 45px;
  margin-bottom: 0px;
  background: #f7f7f8;
  border: 1px solid #e4e5e7;
}
.voucher-text span {
  position: absolute;
  top: 15%;
  right: 18px;
  cursor: pointer;
  color: #0781f2;
  font-size: 14px;
  line-height: 130%;
}
.discount-information {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
  /* width: 278px; */
}
.revert-discount {
  color: #53585f;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-top: 8px;
}
.schedule-meeting {
  text-align: center;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
  cursor: pointer;
}
.schedule-meeting span {
  color: #0781f2;
  cursor: pointer;
}
</style>
