<template>
  <div class="faq-conainer">
    <div class="next-step">WHAT’S NEXT?</div>
    <div class="section-container">
      <div class="section-box">
        <div class="head-title">
          <img src="/img/onboarding/green-bullet.svg" /> - You’re here now
        </div>
        <div class="section-title">1. Meet and Evaluate Product Team</div>
        <div class="section-content">
          Meet with the product team you have reserved before making a final
          decision on whether to work with them or choose a different team.
        </div>
        <div class="cost">Cost: $500</div>
      </div>
      <div class="section-box">
        <div class="head-title">
          <img src="/img/onboarding/unchecked.svg" />
        </div>
        <div class="section-title">2. Design Sprint and Roadmapping</div>
        <div class="section-content">
          Go through the
          <span style="color: #0781f2">design sprint</span> sessions that help
          to get the team invested in your idea and ends with a clear product
          development roadmap.
        </div>
        <div class="cost">
          Cost: $
          {{
            projectDetails.design_thinking_fee.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}<span style="color: #979da5">(you pay 50% upfront)</span>
        </div>
      </div>
      <div class="section-box">
        <div class="head-title">
          <img src="/img/onboarding/unchecked.svg" />
        </div>
        <div class="section-title">3. Kick off Product Development</div>
        <div class="section-content">
          Kick off product development with the first iteration as outlined in
          the roadmap.
        </div>
        <div class="cost">
          Cost: Depends on service plan and number of iterations
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["projectDetails"],
  data() {
    return {
      // isModalVisible: false
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.faq-conainer {
  background: #f7f7f8;
  border-radius: 12px;
  padding: 48px 7%;
}
.section-container {
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
}
.next-step {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: initial;
}
.section-box {
  width: 300px;
  text-align: initial;
}
.head-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
}
.section-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 16px;
}
.section-content {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}
.cost {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 16px;
}
</style>

